import { useAuth } from '~/utils/auth';
import { useRouter } from '~/utils/routing/useRouter';

export default function HomePage() {
  const auth = useAuth();
  const router = useRouter();

  if (typeof window !== 'undefined') {
    router.replace(auth?.username ? '/dashboard/home' : '/sign-in');
  }

  return null;
}
